.titleComponent {
    font-size: 10rem;
    margin-bottom: 5rem;
    opacity: .9;
    position: relative;
    color: #00fde8;
    width: var(--titleWidth);
    @include responsive(1920) {
        font-size: 8rem;
        width: calc(var(--titleWidth) - 8%); }
    @include responsive(1600) {
        font-size: 6rem;
        width: calc(var(--titleWidth) - 18%); }
    @include responsive(1366) {
        width: calc(var(--titleWidth) - 20%); }
    @include responsive(1280) {
        width: calc(var(--titleWidth) - 23%); }
    @include responsive(tablet-landscape) {
        width: calc(var(--titleWidth) - 27%); }
    @include responsive(tablet-portrait) {
        width: calc(var(--titleWidth) - 27%); }

    @include responsive(900) {
        width: calc(var(--titleWidth) - 24%); }
    @include responsive(phone-landscape) {
        margin-bottom: 2rem; }
    @include responsive(800) {
        width: calc(var(--titleWidth) - 28%); }
    @include responsive(700) {
        width: calc(var(--titleWidth) - 33%); }
    @include responsive(670-land) {
        // width: calc(var(--titleWidth) - 44%)
        width: calc(var(--titleWidth) - 39%);
        font-size: 5rem; }
    @include responsive(phone) {
        width: calc(var(--titleWidth) - 39%);
        margin-bottom: 2rem; }
    @include responsive(smallphone-landscape) {
        font-size: 4rem;
        width: calc(var(--titleWidth) - 55%); }
    @include responsive(500) {
        width: calc(var(--titleWidth) - 46%); }
    @include responsive(414-portrait) {
        width: calc(var(--titleWidth) - 70%);
        font-size: 5rem; }
    @include responsive(375-portrait) {
        width: calc(var(--titleWidth) - 72%); }
    @include responsive(smallphone-portrait) {
        font-size: 4rem;
        width: var(--smallTitleWidth);
        margin-bottom: 1rem; }

    &--lightMode {
        color: #ec3a58; }

    &__tag {
        font-family: 'Comic Sans Ms';
        position: absolute;
        font-size: 2rem;
        font-style: italic;
        color: grey;
        @include responsive(1920) {
            font-size: 1.6rem; }
        @include responsive(670-land) {
            font-size: 1.1rem; }
        @include responsive(414-portrait) {
            font-size: 1.1rem; }

        &--topP {
            left: -4%;
            top: 0;
            @include responsive(1600) {
                left: -7%;
                top: -1%; }
            @include responsive(1280) {
                left: -5%; }
            @include responsive(phone) {
                left: -8%; }
            @include responsive(smallphone-landscape) {
                left: -5%; }
            @include responsive(414-portrait) {
                top: 1%; } }

        &--bottomP {
            right: 4%;
            bottom: -4%;
            @include responsive(1600) {
                right: 2%;
                bottom: -7%; }
            @include responsive(700) {
                right: unset;
                left: 50%;
                bottom: -5%; }
            @include responsive(670-land) {
                left: -6%;
                bottom: -7%; }

            @include responsive(phone) {
                left: -10%;
                bottom: -5%; }
            @include responsive(smallphone-landscape) {
                left: -6%;
                bottom: -10%; } }

        &--leftH1 {
            top: 40%;
            left: -4rem;
            @include responsive(700) {
                left: -2rem; }
            @include responsive(phone) {
                left: -4rem; }
            @include responsive(414-portrait) {
                left: -3rem; } }

        &--rightH1 {
            top: 40%;
            right: 0; } } }
