.fullpage {
    position: fixed;
    top: 50%;
    right: 5%;
    z-index: 140;

    &__buttons {
        list-style: none; }

    &__element {
        margin: 1.5rem 0 1rem 1.5rem !important;

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 2rem;
            opacity: .5;
            background-color: rgba(255,255,255,.9); }

        &--active {
            &::before {
                width: 2.5px;
                opacity: 1;
                left: 0px;
                background-color: rgba(255,255,255,.7); } } }

    &__link {
        text-transform: uppercase;
        position: relative;
        padding: .5rem 0 0 1rem;
        display: block;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
        opacity: 0;
        color: rgba(255,255,255,.9);
        transition: opacity .3s ease;

        &:hover {
            opacity: .9; } } }
