.loader {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(0);
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ec3a58;
    z-index: 2000;
    animation: spin 1.5s linear infinite;
    filter: blur(2px);

    &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #00fde8;
        opacity: .9;
        animation: spin 2s linear infinite; }

    &::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #ec3a58;
        opacity: .9;
        animation: spin 3s linear infinite; }
    &__content {
        height: 100%;
        &--initial {
            animation: hide .5s 1s ease-in-out forwards; }
        &--animate {
            opacity: 0;
            animation: show .5s .6s ease forwards; }
        &--animateOut {
            opacity: 1;
            animation: hide .5s 1s ease forwards; } }

    &__wrapper {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: black;
        transform: scaleX(0);
        &--initial {
            transform: scaleX(1);
            animation: scaleIn .5s 1.5s ease-in-out forwards; }
        &--animate {
            animation: scaleOut .5s .2s ease-in-out forwards; }
        &--animateOut {
            transform: scaleX(1);
            animation: scaleIn .5s 1.5s ease-in-out forwards; } }

    &__text {
        color: rgba(255,255,255,0.9);
        font-size: 1.5rem;
        text-transform: uppercase; }

    &__logo {
        height: 10rem;
        width: 10rem;
        z-index: 1002;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-45%); }

    &__infoContainer {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30rem;
        transform: translateX(-25%) translateY(450%);
        display: flex;
        height: 2rem;
        align-items: center; }

    &__dotContainer {
        display: flex;
        margin: .8rem 0 0 .8rem; }

    &__dot {
        height: .5rem;
        width: .5rem;
        background-color: rgba(255,255,255,.9);
        filter: blur(1px);
        border-radius: 100%;
        margin-right: 1rem;
        opacity: 0;
        &--1 {
            animation: show .6s .2s infinite; }
        &--2 {
            animation: show .6s .3s infinite; }
        &--3 {
            animation: show .6s .4s infinite; } } }
