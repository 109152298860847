/* Loader animations */

@keyframes spin {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }

@keyframes scaleIn {
    0% {
        transform: scaleX(1); }
    100% {
        transform: scaleX(0); } }

@keyframes scaleOut {
    0% {
        transform: scaleX(0); }

    100% {
        transform: scaleX(1); } }

@keyframes hide {
    0% {
        opacity: 1; }
    100% {
        opacity: 0; } }

@keyframes show {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

/* Skills page animation */

@keyframes reactlogoRotate {
    0% {
        transform: rotate(0);
        opacity: .8; }
    100% {
        transform: rotate(360deg);
        opacity: .8; } }

@keyframes cupfloat {
    0% {
        transform: rotate(-20deg) translateY(1rem); }
    100% {
        transform: translateY(-1rem); } }

@keyframes cupRotate {
    0% {
        transform: rotate(-360deg); }
    100% {
        transform: rotate(360deg); } }

@keyframes hideShow {
    0% {
        opacity: 1; }
    100% {
        opacity: .1; } }

@keyframes scaleSection {
    0% {
        transform: scale(.9);
        opacity: 0; }
    100% {
        transform: scale(1);
        opacity: 1; } }

@keyframes scaleSectionMore {
    0% {
        transform: scale(.8);
        opacity: 0; }

    100% {
        transform: scale(.9);
        opacity: 1; } }
@keyframes scaleSectionOutMore {
    0% {
        transform: scale(.9);
        opacity: 1; }

    100% {
        transform: scale(.8);
        opacity: .7; } }

@keyframes scaleSectionOut {
    0% {
        transform: scale(1);
        opacity: 1; }
    100% {
        transform: scale(.9);
        opacity: .7; } }

/* About Page */
@keyframes dash {
    0% {
        stroke-dashoffset: 2100; }

    50% {
        stroke-dashoffset: 800; }

    100% {
        stroke-dashoffset: 2100; } }

@keyframes dashleft {
    0% {
        stroke-dashoffset: -2200; }

    100% {
        stroke-dashoffset: -100; } }

/* Contact page */
@keyframes scaleClick {
    0% {
        transform: scale(1); }

    50% {
        transform: scale(.9); }
    100% {
        transform: scale(1); } }

@keyframes hideInfo {
    0% {
        margin-top: 0; }

    100% {
        margin-top: -100%; } }

@keyframes opacityTranslateYShow {
    0% {
        opacity: 0;
        transform: translateY(-1rem); }

    100% {
        opacity: 1;
        transform: translateY(0); } }

@keyframes translateYshow {
    0% {
        transform: translateY(-1rem); }
    100% {
        transform: translateY(0); } }

/* Front Page */

@keyframes animateName {
    0% {
        stroke-dashoffset: 40rem; }

    100% {
        stroke-dashoffset: 0; } }

@keyframes animated-text {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 9rem;
        border-right-width: 0px; } }

@keyframes animated-text-bigger {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 30rem;
        border-right-width: 0px; } }

@keyframes animated-text2600 {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 15.5rem;
        border-right-width: 0px; } }

@keyframes animated-Bigtext2600 {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 50rem;
        border-right-width: 0px; } }

@keyframes animated-Bigtext1600 {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 39rem;
        border-right-width: 0px; } }

@keyframes animated-BigText414 {
    0% {
        width: 0;
        border-right-width: 0px; }
    1% {
        border-right-width: 2px; }
    99% {
        border-right-width: 2px; }
    100% {
        width: 25rem;
        border-right-width: 0px; } }

/* Projects Page */

@keyframes opacity7TranslateYShow {
    0% {
        opacity: 0;
        transform: translateY(-2rem); }

    100% {
        opacity: .7;
        transform: translateY(0); } }
