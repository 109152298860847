.staticArrowContainer {
    color: white;
    font-family: 'Comic Sans Ms';
    font-style: italic;
    position: absolute;
    opacity: 0;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: show 2s 3.5s linear forwards;
    @include responsive(2560) {
        font-size: 1.3rem; }
    @include responsive(tablet-landscape) {
        text-align: center; }
    @include responsive(900) {
        text-align: center; }
    @include responsive(phone) {
        font-size: 1rem; }
    @include responsive(smallphone-landscape) {
        font-size: .7rem; }
    @include responsive(smallphone-portrait) {
        font-size: .7rem; }

    &__textLightMode {
        @include responsive(tablet-landscape) {
            width: 50%;
            margin: 0 auto; }
        @include responsive(900) {
            width: 50%;
            margin: 0 auto; }
        @include responsive(smallphone-landscape) {
            width: 100%; } }

    &__textDarkMode {
        @include responsive(tablet-landscape) {
            width: 50%;
            margin: 0 auto; }
        @include responsive(900) {
            width: 50%;
            margin: 0 auto; }
        @include responsive(500) {
            width: 50%;
            margin: 0 auto; }
        @include responsive(smallphone-landscape) {
            width: 100%; } }

    &--lightMode {
        color: #ec3a58; }
    &__bright {
        top: 5%;
        left: 30%; }
    @include responsive(tablet-landscape) {
        left: 10%;
        top: 2%; }
    @include responsive(900) {
        left: 10%;
        top: 2%; }
    @include responsive(700) {
        top: 1%; }

    &__toggle {
        position: absolute;
        right: 10%;
        top: 10%;
        left: unset;
        width: 20rem;
        z-index: 500;
        @include responsive(1280) {
            right: 5%; }
        @include responsive(phone-landscape) {
            right: 0; }
        @include responsive(phone) {
            top: 4%;
            right: 0;
            width: 12rem; }
        @include responsive(smallphone-portrait) {
            width: 8rem; }
        @include responsive(smallphone-landscape) {
            top: 10%; } } }
