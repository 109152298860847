.aboutPage {
    &__section {
        @include responsive(500) {
            height: 110vh; } }
    &__container {
        width: 50%;
        @include responsive(tablet-landscape) {
            width: 60%; }
        @include responsive(900) {
            width: 75%; } }
    &__innerContainer {
        @include responsive(500) {
            height: 115vh; } } }
