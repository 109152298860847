* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &::selection {
        color: black;
        background-color: #00fde8; } }

html {
    font-size: 62.5%; } // defines that 1 rem - 10px

body {
    font-family: 'Gotham Medium', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif {}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; }
.overscroll-off {
    overflow: hidden; }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace {} }

.fullpage-wrapper {
    background-color: #1D1D1D; }
/* Layout */
/* Section, container, inner container styles */

.section {
    background-color: #1D1D1D;
    height: 100vh;
    overflow: hidden;
    @include responsive(phone-landscape) {
        height: 200vh; }
    @include responsive(670-land) {
        height: 170vh; }
    @include responsive(smallphone-landscape) {
        height: 140vh; }
    &--lightMode {
        background-color: rgba(232, 232, 232, 1); } }

.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
    transform: scale(.9);
    height: 100vh;
    opacity: 0;
    animation: scaleSection .4s 2s ease forwards;
    @include responsive(phone-landscape) {
        height: 200vh;
        transform: scale(.9);
        animation: scaleSectionMore .4s 2s ease forwards; }
    @include responsive(670-land) {
        height: 170vh; }
    @include responsive(500) {
        height: 105vh;
        transform: scale(.9);
        animation: scaleSectionMore .4s 2s ease forwards; }
    @include responsive(smallphone-landscape) {
        height: 140vh; }
    &--loading {
        animation: scaleSectionOut .4s ease forwards;
        @include responsive(phone-landscape) {
            animation: scaleSectionOutMore .4s ease forwards; }
        @include responsive(500) {
            animation: scaleSectionOutMore .4s ease forwards; } } }

.container {
    width: 60%;
    color: white;
    transition: all .2s ease;
    @include responsive(900) {
        width: 75%; }
    @include responsive(700) {
        width: 85%; } }

/* Line Element */

.lineElement {
    position: absolute;
    bottom: 5.3%;
    transform: rotate(2deg);
    // width: 99%
    @include responsive(ipadPro-portrait) {
        width: 103%;
        left: -3%; }
    @include responsive(900) {
        width: 103%;
        left: -3%; }
    @include responsive(phone-landscape) {
        display: none; }
    @include responsive(700) {
        display: none; }
    &--dark {
        path {
            stroke: rgba(0,0,0,.5); } }

    path {
        stroke-dasharray: 2200;
        stroke-dashoffset: 2100;
        stroke-miterlimit: 10;
        stroke-width: 4;
        stroke: rgba(255,255,255,.5);
        fill: none;
        animation: dash 2s 2s ease forwards, dashleft 2s 4s ease forwards; } }

/* FONTS */

@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Gotham-Medium.otf"); }

@font-face {
    font-family: 'Gotham Book';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Gotham-Book.otf"); }

@font-face {
    font-family: 'Comic Sans Ms';
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/Comic-Sans-Ms.ttf"); }

@font-face {
    font-family: "Brush Script MT Italic";
    src: url("../../fonts/BRUSHSCI.TTF"); }
