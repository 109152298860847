.navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 5vw;
    background-color: #181818;
    z-index: 1000;
    @include responsive(ipadPro-portrait) {
        display: none; }
    @include responsive(900) {
        display: none; }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh; }

    &__linksContainer {
        height: 55%;
        @include responsive(900) {
            height: 45%; } }

    &__infoTextContainer {
        font-family: 'Brush Script MT Italic';
        color: rgba(255,255,255,.7);
        position: absolute;
        display: flex;
        align-items: center;
        width: 30rem;
        margin-top: 3px;
        font-size: 2rem;
        left: 300%;
        display: flex;
        @include responsive(1920) {
            width: 20rem;
            left: 250%;
            font-size: 1.8rem; }
        @include responsive (1600) {
            font-size: 1.5rem; }
        @include responsive(1280) {
            font-size: 1.3rem;
            left: 200%; }
        @include responsive(tablet-landscape) {
            font-size: 1.2rem;
            left: 150%; }
        @include responsive(phone) {
            width: 17rem; }
        &--lightMode {
            color: rgba(0,0,0,.7); }

        &--active {
            animation-delay: .5s !important; }
        &--about {
            opacity: 0;
            animation: opacityTranslateYShow .4s 4.6s ease forwards; }
        &--skills {
            opacity: 0;
            animation: opacityTranslateYShow .4s 4.8s ease forwards; }

        &--projects {
            opacity: 0;
            animation: opacityTranslateYShow .4s 5s ease forwards; }

        &--contact {
            opacity: 0;
            animation: opacityTranslateYShow .4s 5.2s ease forwards; } }

    &__element {
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5rem;
        @include responsive(900) {
            margin-bottom: 2.5rem; }
        @include responsive(phone-landscape) {
            margin-bottom: 1.5rem; }
        &:hover {
            .navbar__text {
                opacity: 1; }
            .navbar__icon {
                opacity: .7;
                filter: blur(2px); } } }

    &__icon {
        color: grey;
        transition: all .2s ease;
        pointer-events: none;
        font-size: 2.5rem;
        @include responsive(900) {
            font-size: 2rem; }
        &--active, {
            color: rgba(0, 253, 232, .8); } }

    &__link {
        color: grey;
        font-size: 2rem;
        transition: all .2s ease;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        margin-bottom: 2rem;
        &:hover {
            color: rgba(0, 253, 232, .5);
            transform: scale(1.5); } }

    &__text {
        opacity: 0;
        position: absolute;
        font-size: 1.1rem;
        color: rgba(0, 253, 232, .8);
        text-transform: uppercase;
        transition: all .2s ease;
        pointer-events: none;
        font-family: 'Gotham Medium'; }

    &__logoContainer {
        height: 30%;
        @include responsive(900) {
            height: 20%; }
        @include responsive(phone-landscape) {
            margin-top: -1rem; } }

    &__logo {
        margin-top: 2rem;
        pointer-events: none;
        width: 7rem;
        height: 7rem;
        @include responsive(900) {
            height: 5rem;
            width: 5rem; }
        @include responsive(phone-landscape) {
            margin-top: 1rem; } }

    &__links {
        height: 15%; } }

.sidebar {
    display: none;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 6rem;
    @include responsive(ipadPro-portrait) {
        display: block; }
    @include responsive(900) {
        display: block; } }

/* Hamburger Icon */
.hamburger {
    position: fixed;
    top: 2%;
    left: 0;
    color: rgba(255,255,255,.7);
    z-index: 151;
    display: none;
    @include responsive(ipadPro-portrait) {
        display: block;
        left: 1%; }
    @include responsive(900) {
        display: block; }
    &--lightMode {
        color: rgba(0,0,0,.7); }
    &--active {} }

/* Back Drop */

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 145; }
