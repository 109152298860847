.toggleDark {
    &__toggle {
        width: 2.2rem;
        height: 5rem;
        border-radius: 1rem;
        background-color: none;
        border: 1px solid rgba(255,255,255,0.4);
        cursor: pointer;
        z-index: 1000;
        &--lightMode {
            border: 1px solid rgba(0,0,0,0.5); }

        @include responsive(tablet-landscape) {
            right: 15%; }
        @include responsive(smallphone-landscape) {
            width: 1.4rem;
            height: 3rem; }
        @include responsive(smallphone-portrait) {
            width: 1.4rem;
            height: 3rem; } }

    &__circle {
        width: 1.7rem;
        height: 1.7rem;
        margin: .4rem 0 0 1.5px;
        background-color: rgba(255,255,255,.9);
        border-radius: 50%;
        transition: all .3s ease-in-out;
        @include responsive(smallphone-landscape) {
            width: 1rem;
            height: 1rem;
            margin: .4rem 0 0 1px; }

        @include responsive(smallphone-portrait) {
            width: 1rem;
            height: 1rem;
            margin: .4rem 0 0 1px; }
        &--lightMode {
            margin: 2.7rem 0 0 2px;
            background-color: rgba(0,0,0,0.8);
            @include responsive(smallphone-landscape) {
                margin-top: 1.6rem;
                margin-left: 1px; }

            @include responsive(smallphone-portrait) {
                margin-top: 1.6rem;
                margin-left: 1px; } } } }
