@mixin responsive($breakpoint) {

    @if $breakpoint == 280-portrait {
        @media only screen and (max-width: 17.5em) and (orientation: portrait) {  // 320px --> 600 / 16
            @content; } }

    @if $breakpoint == smallphone-landscape { // 576 px
        @media only screen and (max-width: 36em) and (orientation: landscape) {
            @content; } }

    @if $breakpoint == smallphone-portrait {
        @media only screen and (max-width: 20em) and (orientation: portrait) {  // 320px --> 600 / 16
            @content; } }

    @if $breakpoint == 375-portrait { // 375px
        @media only screen and (max-width: 23.5em) and (orientation: portrait) { // 375
            @content; } }

    @if $breakpoint == 414-portrait { // 414px
        @media only screen and (max-width: 26em) and (orientation: portrait) {
            @content; } }

    @if $breakpoint == 500 { // 500px
        @media only screen and (max-width: 31.25em) {
            @content; } }

    @if $breakpoint == 550 { // 550px
        @media only screen and (max-width: 34.375em) {
            @content; } }

    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {  // 600px
            @content; } }

    @if $breakpoint == 670-land { //670px
        @media only screen and (max-width: 42em) and (orientation: landscape) {
            @content; } }

    @if $breakpoint == phone-landscape {
        @media only screen and (max-width: 50.75em) and (orientation: landscape) {   // 812px
            @content; } }

    @if $breakpoint == 650 {  // 650 px
        @media only screen and (max-width: 40.625em) {
            @content; } }

    @if $breakpoint == 700 {  // 700 px
        @media only screen and (max-width: 43.5em) {
            @content; } }

    @if $breakpoint == 800 {  // 800 px
        @media only screen and (max-width: 50em) {
            @content; } }

    @if $breakpoint == 900 {  // 900 px
        @media only screen and (max-width: 57em) {
            @content; } }

    @if $breakpoint == 950 {  // 950 px
        @media only screen and (max-width: 59.5em) {
            @content; } }

    @if $breakpoint == tablet-landscape {  // 1024 px
        @media only screen and (max-width: 64em) and (orientation: landscape) {
            @content; } }

    @if $breakpoint == ipadPro {  // 1024 px
        @media only screen and (max-width: 64em) and (min-height: 65em ) {
            @content; } }

    @if $breakpoint == ipadPro-portrait {  // 1024 px
        @media only screen and (max-width: 64em) and (min-height: 65em ) and (orientation: portrait) {
            @content; } }

    @if $breakpoint == tablet-portrait {  // 1024 px // 768 px
        @media only screen and (max-width: 64em) and (orientation: portrait) {
            @content; } }

    @if $breakpoint == 1100 {  // 1100 px
        @media only screen and (max-width: 68.75em) {
            @content; } }

    @if $breakpoint == 1200 {  // 1200 px
        @media only screen and (max-width: 75em) {
            @content; } }

    @if $breakpoint == 1280 {  // 1280 px
        @media only screen and (max-width: 80em) {
            @content; } }

    @if $breakpoint == 1366 {  // 1366 px
        @media only screen and (max-width: 85.5em) {
            @content; } }
    @if $breakpoint == 1366-portrait {  // 1366 px
        @media only screen and (max-width: 85.5em) and (orientation: portrait) {
            @content; } }

    @if $breakpoint == 1600 {  // 1600 px
        @media only screen and (max-width: 100em) {
            @content; } }

    @if $breakpoint == 1920 {  // 1930 px
        @media only screen and (max-width: 120em) {
            @content; } }

    @if $breakpoint == 2200 {  // 2200 px
        @media only screen and (max-width: 137.5em) {
            @content; } }

    @if $breakpoint == 2560 {  // 2560 px
        @media only screen and (max-width: 160em) {
            @content; } } }
